import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import isPassword from "../utils/isPassword";
import { Popover, Transition } from "@headlessui/react";
import PasswordChecklist from "./PasswordChecklist";

const PasswordInput = ({
  title,
  password,
  setPassword,
  checklist,
  onKeyDown,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    password.length > 0 && !isPassword(password)
      ? setOpen(true)
      : setOpen(false);
  }, [password]);

  return (
    <>
      <div className="inline-flex space-x-3 mt-1 items-center">
        <p className="text-2xl font-bold text-dim_gray dark:text-powder">
          {title}
        </p>{" "}
        {password && (
          <button
            onClick={() => setShowPassword(!showPassword)}
            className="self-center hover:scale-110 flex hover:text-harvest_gold text-dim_gray dark:text-powder"
          >
            {showPassword ? (
              <FaEyeSlash className="h-4 w-4" />
            ) : (
              <FaEye className="h-4 w-4" />
            )}
          </button>
        )}
      </div>
      <div className="relative group">
        <input
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={onKeyDown}
          className={`p-3 my-2 w-full bg-powder rounded ${
            isPassword(password)
              ? "outline outline-green-500"
              : password.length > 0 && "outline outline-harvest_gold"
          }`}
          type={showPassword ? "text" : "password"}
          placeholder={`Your ${title.toLowerCase()}`}
        />
        {open && checklist && (
          <Popover
            className={
              "absolute hidden z-50 group-focus-within:block w-full bg-harvest_gold p-3 shadow-xl rounded-xl"
            }
          >
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel static onClick={() => setOpen(false)}>
                <PasswordChecklist password={password} />
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
      </div>
    </>
  );
};

export default PasswordInput;
