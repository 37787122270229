import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import "./blob-reversed.css"; // Add this line for the blob styling
import LogInProviders from "../components/LogInProviders";
import PasswordInput from "../components/PasswordInput";
import EmailInput from "../components/EmailInput";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, logIn, error, setError } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setError("");
  }, [setError]);

  useEffect(() => {
    if (user != null) {
      navigate("/library");
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await logIn(email, password);
    } catch (error) {
      // Handle error if needed
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className="w-full h-screen overflow-hidden relative justify-center flex my-4">
      <div className="blob-reversed top-8 flex z-0"></div>
      <div className="w-full px-4 py-24 z-10">
        <div className="w-6/6 sm:w-3/6 xl:w-2/6 h-auto mx-auto text-black rounded">
          <div className="w-5/6 md:w-4/6 mx-auto py-4">
            <h1 className="text-5xl font-bold text-center text-marian_blue dark:text-powder p-3">
              Log in
            </h1>
            <LogInProviders buttonText={"Log in with "} />
            <form
              onSubmit={handleSubmit}
              action=""
              className="w-full flex flex-col py-4"
            >
              <EmailInput
                email={email}
                setEmail={setEmail}
                title={true}
                onKeyDown={handleKeyDown}
              />
              <PasswordInput
                title="Password"
                password={password}
                setPassword={setPassword}
                onKeyDown={handleKeyDown}
              />
              <p
                onClick={handleForgotPassword}
                className="cursor-pointer text-marian_blue dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
              >
                Forgot password?
              </p>
              {error && <p className="text-red-600 text-lg mt-2">{error}</p>}
              <button className="bg-marian_blue group hover:active:bg-pumpkin hover:bg-bright_pink dark:bg-gunmetal dark:hover:bg-harvest_gold dark:active:bg-pumpkin transition-all duration-150 text-powder py-3 my-6 rounded">
                <div className="group-hover:scale-110 transition-all duration-150">
                  Log in
                </div>
              </button>
              <p className="flex flex-col items-center">
                <span>Don't have an account?</span>
                <Link
                  to="/signup"
                  className="px-1 hover:scale-110 transition-all duration-150 items-center inline-flex text-marian_blue dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
                >
                  Sign up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
