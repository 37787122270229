import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Popover, Transition } from "@headlessui/react";

// import PasswordChecklist from "../components/PasswordChecklist";

const ResetPassword = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const { confirmThePasswordReset } = UserAuth();

  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const [hasUppercaseAndLowercase, setHasUppercaseAndLowercase] =
    useState(false);
  useEffect(() => {
    setError("");
  }, []);
  useEffect(() => {
    // Check if the password contains both uppercase and lowercase letters
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    setHasUppercaseAndLowercase(hasUppercase && hasLowercase);
  }, [password]);

  const isRepeatPasswordValid = () => {
    return repeatPassword.length > 0 && password === repeatPassword;
  };

  const resetFormFields = () => {
    setPassword("");
    setRepeatPassword("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== repeatPassword) {
      setError("Passwords did not match.");
      return;
    }

    try {
      let oobCode = new URLSearchParams(window.location.search).get("oobCode");
      if (oobCode) {
        await confirmThePasswordReset(oobCode, repeatPassword);
        resetFormFields();
        setSuccess("Password reset successful!");
        setError("");
      } else {
        setError("Something is wrong; try again later!");
        setSuccess("");
        //console.log("missing oobCode");
      }
    } catch (error) {
      if (error.code === "auth/invalid-action-code") {
        setError("Something is wrong; try again later.");
        setSuccess("");
      }
      // console.log(error.message);
    }
  };
  const isPasswordValid = () => {
    // Password should be at least 8 characters long
    if (password.length < 8) {
      return false;
    }

    // Check for a mix of capital and lower-case letters
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    if (!(hasUpperCase && hasLowerCase)) {
      return false;
    }

    // Check for at least one number and one symbol
    const hasNumber = /\d/.test(password);
    // const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    if (!hasNumber) {
      return false;
    }

    // Password meets all requirements
    return true;
  };

  useEffect(() => {
    password.length > 0 && !validPassword ? setOpen(true) : setOpen(false);
    setValidPassword(isPasswordValid(password));
  }, [password]);

  useEffect(() => {
    isRepeatPasswordValid(repeatPassword);
  }, [repeatPassword]);

  useEffect(() => {
    validPassword === true && setOpen(false);
  }, [validPassword]);

  const PasswordChecklist = (
    <>
      {" "}
      {/* Password requirements checklist */}
      <div className="flex flex-col text-powder dark:text-powder mt-2">
        <label>
          <input
            type="checkbox"
            checked={password.length > 8}
            readOnly
            className="mr-2"
          />
          At least 8 characters
        </label>
        <label>
          <input
            type="checkbox"
            checked={hasUppercaseAndLowercase}
            readOnly
            className="mr-2"
          />
          Mix of uppercase and lowercase letters
        </label>
        <label>
          <input
            type="checkbox"
            checked={/\d/.test(password)}
            readOnly
            className="mr-2"
          />
          At least one number
        </label>
      </div>
    </>
  );

  // If oobCode is missing, redirect to 404 page
  if (!new URLSearchParams(window.location.search).get("oobCode")) {
    return <Navigate to="/forgot-password" />;
  }
  return (
    <div className="w-full h-screen overflow-hidden relative justify-center  flex my-4">
      <div className="blob  top-8 flex z-0"></div>
      <div className="z-10">
        <div className="w-full h-5/6  px-4 py-24 z-50">
          <div className="h-auto mx-auto  text-black rounded">
            <div className="md:w-4/6 mx-auto py-4">
              <div className="flex items-center justify-center flex-col">
                <h1 className="text-5xl font-bold text-center text-marian_blue dark:text-powder p-3 ">
                  Reset Password
                </h1>
                {/* <LogInProviders buttonText={"Log in with "} /> */}
                <form
                  onSubmit={handleSubmit}
                  action=""
                  className="w-full flex flex-col py-4"
                >
                  <div className="inline-flex space-x-3  items-center">
                    <p className="text-2xl font-bold text-dim_gray dark:text-powder">
                      Password
                    </p>{" "}
                    {password && (
                      <button
                        onClick={() => setShowPassword(!showPassword)}
                        className="text-dim_gray self-center hover:scale-110 flex dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
                      >
                        {showPassword ? (
                          <FaEyeSlash className="h-4 w-4" />
                        ) : (
                          <FaEye className="h-4 w-4" />
                        )}
                      </button>
                    )}
                  </div>
                  <div className="relative w-full group">
                    <input
                      autoComplete="new-password"
                      onChange={(e) => setPassword(e.target.value)}
                      className={`p-3 my-2 w-full  bg-powder rounded ${
                        validPassword
                          ? "outline outline-green-500"
                          : password.length > 0 &&
                            "outline outline-harvest_gold"
                      }`}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      // autoComplete="current-password"
                    />{" "}
                    {open && (
                      <Popover
                        className={
                          "absolute hidden z-50 group-focus-within:block w-full  bg-harvest_gold p-3 shadow-xl rounded-xl"
                        }
                      >
                        <Transition
                          show={open}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Popover.Panel static onClick={() => setOpen(false)}>
                            {PasswordChecklist}
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    )}
                  </div>

                  <div className="inline-flex space-x-3  items-center">
                    <p className="text-2xl font-bold text-dim_gray dark:text-powder">
                      Repeat password
                    </p>{" "}
                    {repeatPassword && (
                      <button
                        onClick={() =>
                          setShowRepeatPassword(!showRepeatPassword)
                        }
                        className="text-dim_gray self-center hover:scale-110 flex dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
                      >
                        {showRepeatPassword ? (
                          <FaEyeSlash className="h-4 w-4" />
                        ) : (
                          <FaEye className="h-4 w-4" />
                        )}
                      </button>
                    )}
                  </div>
                  <input
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    className={`p-3 my-2 bg-powder rounded ${
                      isRepeatPasswordValid()
                        ? "outline outline-green-500"
                        : repeatPassword.length > 0 &&
                          "outline outline-harvest_gold"
                    }`}
                    type={showRepeatPassword ? "text" : "password"}
                    placeholder="Repeat Password"
                    autoComplete="off"
                  />
                  {error && (
                    <p className="text-red-600 text-lg mt-2 whitespace-nowrap">
                      {error}
                    </p>
                  )}
                  {success && (
                    <p className="text-center text-green-700 dark:text-green-500 font-extrabold text-lg mt-2">
                      {success}
                    </p>
                  )}
                  <button
                    disabled={!isPasswordValid() || !isRepeatPasswordValid()}
                    className={`${
                      !isPasswordValid() || !isRepeatPasswordValid()
                        ? "bg-gray-300 opacity-60 cursor-not-allowed"
                        : "bg-marian_blue group hover:active:opacity-75 dark:bg-gunmetal hover:bg-green-500 "
                    } transition-all duration-150 text-powder py-3 my-4 rounded font-bold`}
                  >
                    <div className="group-hover:scale-110 transition-all duration-150">
                      Reset Password
                    </div>
                  </button>
                </form>
                {success && (
                  <Link
                    to="/login"
                    className="w-full justify-center whitespace-nowrap hover:scale-110 transition-all duration-150 items-center inline-flex text-marian_blue dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
                  >
                    Back to login
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
