import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import "./blob.css"; // Add this line for the blob styling
import { auth } from "../firebase"; // Assuming you have a Firebase configuration file
import { useNavigate, useSearchParams } from "react-router-dom";

const EmailVerification = () => {
  const { user, confirmUserEmail } = UserAuth();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [confirmEmailSuccess, setConfirmEmailSuccess] = useState(
    user ? user.emailVerified : false
  );

  let oobCode = searchParams.get("oobCode");
  const reloadUser = async () => {
    try {
      await auth.currentUser.reload();
      return auth.currentUser;
    } catch (error) {
      //console.error("Error reloading user:", error);
      throw error;
    }
  };
  // Function to fetch user data and update verification status
  const fetchUserData = async () => {
    try {
      await reloadUser(); // Assuming reloadUser is a function to refresh user data
      setConfirmEmailSuccess(user ? user.emailVerified : false);
    } catch (error) {
      //console.error("Error fetching user data:", error);
    }
  };

  // Use useEffect to periodically fetch user data (every 5 seconds in this example)
  useEffect(() => {
    const intervalId = setInterval(fetchUserData, 5000); // 5 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Only re-run the effect if the user object changes

  // The hook is running twice when <React.StrictMode> is activated
  // causing the oobCode to be reused.
  useEffect(() => {
    if (user.emailVerified) {
      setConfirmEmailSuccess(true);
    }
    if (oobCode) {
      try {
        confirmUserEmail(oobCode);

        setConfirmEmailSuccess(true);
      } catch (error) {
        alert(error);
      }
    }

    // cleanup function.
    return () => {
      oobCode = null;
    };
  }, []);

  return (
    <div className="flex flex-col h-screen items-center justify-center">
      {/* Blob for decoration */}
      <div className="blob  top-30 flex z-0"></div>

      {/* Render different content based on user verification status */}
      {confirmEmailSuccess === false ? (
        <div className="z-10">
          <h1 className="text-5xl font-bold text-center mb-6 text-marian_blue dark:text-powder">
            Email Verification Required
          </h1>
          <p className="mb-4 font-bold text-center text-dim_gray dark:text-powder">
            Please check your emails and verify your account to gain access.
          </p>
        </div>
      ) : (
        <div className="z-10">
          <h1 className="text-5xl font-bold text-center mb-6 text-marian_blue dark:text-powder">
            Email Verified
          </h1>
          <p className="mb-4 font-bold text-center text-dim_gray dark:text-powder">
            Congratulations! Your email has been successfully verified. Welcome
            aboard!
          </p>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
