import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import { UserAuth } from "../context/AuthContext";
import PasswordInput from "./PasswordInput";
import isPassword from "../utils/isPassword";

const DeleteAccountModal = ({ openModal, setOpenModal }) => {
  const { user, deleteUserAccount } = UserAuth();
  const [delay, setDelay] = useState(5); // Initial delay of 5 seconds
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [password, setPasswrod] = useState("");
  useEffect(() => {
    if (delay === 0) {
      setIsButtonEnabled(true);
      return; // If delay reaches 0, stop the timer
    }

    const timer = setTimeout(() => {
      setDelay((prevDelay) => prevDelay - 1); // Decrease delay by 1 every second
    }, 1000);

    return () => clearTimeout(timer); // Cleanup function to clear the timer
  }, [delay]);

  const handleAccountDelete = () => {
    deleteUserAccount(user, password);
  };

  return (
    <Transition appear show={openModal} as={Fragment}>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-gunmetal p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title className="text-lg font-medium leading-6 text-red-600">
                  <div className="inline-flex items-center justify-center">
                    {" "}
                    <FaExclamationTriangle className="me-2 " /> Delete account
                  </div>
                </Dialog.Title>
                <Dialog.Description className="text-red-500 font-semibold animate-pulse">
                  This will permanently deactivate your account
                </Dialog.Description>

                <div className="mt-2">
                  <p className="text-sm text-dim_gray dark:text-powder">
                    Are you sure you want to deactivate your account? All of
                    your data will be permanently removed. This action cannot be
                    undone.
                  </p>
                </div>
                <PasswordInput
                  title="Password"
                  password={password}
                  setPassword={setPasswrod}
                />
                <div className="mt-3 w-full text-end">
                  <button
                    disabled={!isButtonEnabled || !isPassword(password)}
                    className="disabled:opacity-50 disabled:hover:bg-red-100 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    onClick={handleAccountDelete}
                  >
                    {isButtonEnabled
                      ? "Permanently Delete"
                      : `Wait ${delay} seconds`}
                  </button>

                  <button
                    className="px-4 text-dim_gray dark:text-powder hover:text-black"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteAccountModal;
