import React from "react";
import { Link, Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import "./water.css";

const Home = () => {
  const { user } = UserAuth();

  if (user) {
    return <Navigate to="/library" />;
  } else {
    return (
      <div className="flex flex-col w-full items-center justify-center h-screen overflow-hidden bg-stackoverflow_gray dark:bg-gunmetal z-10  relative">
        <h1 className="text-6xl font-black text-marian_blue  dark:text-powder py-2 mb-5 z-0">
          Steinadler's Digital Library
        </h1>
        <div className="z-10 flex justify-center flex-col items-center">
          <p className="text-xl text-dim_gray dark:text-powder py-2">
            Where Learning German is Child's Play.
          </p>{" "}
          <div className="flex flex-row text-center space-x-10">
            {" "}
            <div className="flex flex-col">
              <p className="text-xl text-dim_gray dark:text-powder py-3">
                Ready to learn?
              </p>
              <div className="flex-row space-x-5">
                <Link to="/login">
                  <button className="w-32  bg-marian_blue hover:scale-110 hover:active:bg-pumpkin hover:bg-bright_pink dark:bg-gunmetal dark:hover:bg-harvest_gold dark:active:bg-pumpkin transition-all duration-150 text-powder  px-4 py-2 mt-1 rounded">
                    Login
                  </button>
                </Link>{" "}
                <Link to="/signup">
                  <button className="w-32 bg-powder ring-marian_blue dark:ring-gunmetal dark:text-gunmetal  ring-2 ring-inset hover:scale-110 hover:active:bg-pumpkin hover:bg-bright_pink hover:ring-0 hover:text-powder dark:hover:bg-harvest_gold dark:active:bg-pumpkin transition-all duration-150 text-marian_blue  px-4 py-2 mt-1 rounded">
                    Sign Up
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="water dark::after:bg-gunmetal dark::before:bg-taupe">
          {" "}
        </div>
      </div>
    );
  }
};

export default Home;
