import React from "react";
import isMixedCase from "../utils/isMixedCase";

const PasswordChecklist = ({ password }) => {
  // Initial checks
  if (!password) return null;

  return (
    <div className="flex flex-col text-powder dark:text-powder mt-2">
      <label htmlFor="password-length">
        <input
          type="checkbox"
          id="password-length"
          checked={password.length > 8}
          readOnly
          className="mr-2"
        />
        At least 8 characters
      </label>
      <label htmlFor="mixed-case">
        <input
          type="checkbox"
          id="mixed-case"
          checked={isMixedCase(password)}
          readOnly
          className="mr-2"
        />
        Mix of uppercase and lowercase letters
      </label>
      <label htmlFor="one-number">
        <input
          type="checkbox"
          id="one-number"
          checked={/\d/.test(password)}
          readOnly
          className="mr-2"
        />
        At least one number
      </label>
    </div>
  );
};

export default PasswordChecklist;
