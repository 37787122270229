import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  collection,
  doc,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import EmailInput from "../components/EmailInput";
import isEmail from "../utils/isEmail";
import { db } from "../firebase";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const { resetPasswordEmail, error, setError } = UserAuth();
  useEffect(() => {
    setError("");
  }, []);

  const handleEmailReset = async (e) => {
    e.preventDefault();
    try {
      await resetPasswordEmail(email);
      setSuccess("If the email exists, a password reset link will be sent");
      setEmail(""); // Clear the email input
      setError("");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="w-full h-screen overflow-hidden relative justify-center  flex my-4">
      <div className="blob  top-8 flex z-0"></div>
      <div className="z-10">
        <div className="w-full h-5/6  px-4 py-24 z-50">
          <div className=" w-1/2 h-auto mx-auto  text-black rounded">
            <div className="mx-auto py-4">
              <div className="flex items-center justify-center flex-col">
                <h1 className="text-5xl font-bold text-center text-marian_blue dark:text-powder p-3 ">
                  Forgot Password
                </h1>
                <form className="flex flex-col">
                  <p className="text-center mb-5">
                    Enter your email below to reset your password.
                  </p>
                  <EmailInput email={email} setEmail={setEmail} title={false} />
                  {error && (
                    <p className="text-center text-red-700 dark:text-red-300 font-extrabold text-lg mt-2">
                      {error}
                    </p>
                  )}
                  {success && (
                    <p className="text-center text-green-700 dark:text-green-500 font-extrabold text-lg mt-2">
                      {success}
                    </p>
                  )}
                  <button
                    onClick={(e) => handleEmailReset(e)}
                    disabled={!isEmail(email)}
                    className="bg-marian_blue group hover:active:bg-pumpkin hover:bg-bright_pink dark:bg-gunmetal dark:hover:bg-harvest_gold dark:active:bg-pumpkin 
                    transition-all duration-150 
                    text-powder py-3 my-6 rounded 
                    disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <div className="group-hover:scale-110 transition-all duration-150">
                      Reset Password
                    </div>
                  </button>
                </form>
                <Link
                  to="/login"
                  className="w-full justify-center whitespace-nowrap hover:scale-110 transition-all duration-150 items-center inline-flex text-marian_blue dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
                >
                  Back to login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
