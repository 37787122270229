import React from "react";

const SteinadlerLogo = ({}) => {
  return (
    <svg
      className="h-12 self-center dark:fill-powder fill-marian_blue"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="125px"
      height="67.981px"
      viewBox="0 0 125 67.981"
      enableBackground="new 0 0 125 67.981"
      xmlSpace="preserve"
    >
      <path
        fill="none"
        d="M38.472,54.665c0.041,0.033,0.1,0.027,0.172,0.002C38.516,54.644,38.443,54.637,38.472,54.665z"
      />
      <g>
        <path
          fill="none"
          d="M52.337,34.814l-15.05,20.484l3.846,0.776l7.137,1.221l5.14,0.555l1.886,0.296l1.146,0.776l0.665,0.555
			l1.072,0.48l1.073,0.296c0,0,0.407,0.037,0.592,0c0.185-0.037,2.034-0.259,2.034-0.259l1.814,0.555l4.656,1.442l3.957,1.368
			l9.983,3.586l13.017-24.552l-0.841-1.664l-1.23-0.074c0,0-2.597-0.099-3.214-0.271c-0.883-0.245-3.442-1.245-3.442-1.245
			l-4.125-1.382c0,0-2.768-0.938-3.649-1.152c-1.025-0.248-3.155-0.757-4.206-0.651c-0.891,0.089-3.912,1.029-3.912,1.029
			s-1.98-2.161-2.854-2.553c-0.801-0.359-2.399-0.806-4.955-0.689c-1.023,0.047-3.897,1.097-4.858,1.257
			c-1.389,0.231-1.768,0.009-2.389,0.074C54.478,35.195,52.337,34.814,52.337,34.814z"
        />
        <g>
          <path
            d="M96.499,21.896c7.609-0.313,16.496-8.229,18.14-9.985c1.77-1.883,11.676-12.789,10.215-11.854
				c-1.456,0.936-7.297,8.114-13.55,12.171c-6.254,4.053-13.033,7.899-21.476,7.068c-8.441-0.834-15.435-0.412-19.498,2.389
				c-3.959,2.914-6.564,6.343-6.564,6.343s-0.627,3.225,1.771,1.459c2.399-1.771,5.418-6.347,12.301-7.283
				C84.72,21.274,88.882,22.205,96.499,21.896z"
          />
          <path
            d="M121.306,11.396c-6.153,8.103-14.588,12.892-17.511,14.347c-2.917,1.458-8.34,1.25-16.158,2.915
				c-7.822,1.662-10.636,4.261-10.425,5.613c0.21,1.349,2.501,1.56,3.232,0.834c0.727-0.729,3.752-3.125,10.84-4.368
				c7.09-1.249,12.304-1.663,17.723-5.722C114.43,20.962,122.98,10.356,121.306,11.396z"
          />
          <path
            d="M65.717,20.962c0,0-3.915,3.456-12.848-1.014c-8.178-4.092-13.883,1.119-13.883,1.119
				s-3.265,1.656-1.842,5.347c0.027,0.07-7.002,0.981-7.502,5.106c-0.15,1.226,2.006,3.243,2.353,2.865
				c0.344-0.381-1.206-2.015-0.227-3.563c1.705-2.71,8.723-2.677,8.723-2.677s-3.445-4.993,1.402-6.404
				c0.93-0.269,5.141-1.729,11.164,0.277c2.904,0.97,3.521,1.9,6.795,2.322C63.263,24.318,65.717,20.962,65.717,20.962z"
          />
        </g>
        <path
          d="M39.869,53.396c0,0,4.366,3.312,10.432,1.958c3.331-0.743,9.455,2.672,9.455,2.672
			s0.366-0.083,2.009-0.382c1.644-0.298,4.146-0.393,6.128,0.855c1.978,1.246,7.07,4.274,11.864,3.751
			c1.396-2.262,12.78-21.793,12.78-21.793L79.333,61.942c0,0-4.573,0.821-10.931-3.482c-3.549-2.399-8.537-0.708-8.537-0.708
			s8.226-14.511,9.301-16.639l-9.595,16.348c0,0-5.994-2.706-9.155-2.342c-7.969,0.907-10.485-1.91-10.485-1.91L39.869,53.396z"
        />
        <path
          fill="none"
          d="M94.348,40.414c-1.561,2.083-11.776-3.113-16.677-4.154c-4.903-1.042-5.424,0.828-6.987,0.725
			c-1.46-1.452-2.78-2.418-4.583-2.91c-1.885-0.512-5.668-0.037-7.299,0.936c-1.566,0.934-5.839-0.314-6.047,0.518
			c-0.203,0.802-12.321,18.546-14.112,19.139c0.742,0.152,3.884,1.126,5.668,1.657c2.083,0.631,6.667,0.521,9.381,0.631
			c2.71,0.098,4.172,3.326,6.461,2.491c2.293-0.832,7.723,0.154,10.12,1.928c2.399,1.765,10.212,4.582,10.528,3.179
			C81.113,63.146,95.912,38.339,94.348,40.414z"
        />
        <path
          d="M98.356,38.174c-1.549,0.323-2.84,0.802-3.895,1.328c-0.945,0.464-1.469,0.634-1.469,0.634v0
			c-0.188,0.024-0.367,0.073-0.533,0.154c-0.153,0.079-0.356,0.115-0.616,0.115c-1.458,0-4.747-1.181-8.237-2.43
			c-1.443-0.517-2.932-1.053-4.337-1.52c-1.232-0.411-2.46-0.618-3.65-0.618c-1.624,0-2.867,0.382-3.688,0.631
			c-0.442,0.135-0.757,0.237-0.99,0.237c-0.279,0-0.399-0.145-0.449-0.264c-0.015-0.024-1.364-2.856-5.944-2.856
			c-1.078,0-2.26,0.164-3.507,0.483c-1.756,0.453-3.357,0.682-4.739,0.682c-1.901,0-3.96-0.785-4.169-0.831
			c-0.121-0.025-0.232-0.052-0.284-0.067c-0.131,0.351-0.188,0.497-0.341,0.802c-1.491-0.652-5.102-2.983-9.948-3.19
			c-4.901-0.213-7.51,1.661-7.51,1.661s-0.936,1.664,0.42,1.143c1.354-0.518,2.608-1.143,8.968-0.729
			c3.059,0.201,5.698,1.075,7.623,1.924c-0.835,1.42-2.188,3.325-3.755,5.404c-1.188-1.044-3.532-2.381-7.008-2.799
			c-4.261-0.521-9.038,3.101-19.747,3.675C9.846,42.324,7.771,41.52,0,36.062c1.154,1.256,4.03,3.904,5.53,5.285
			c1.494,1.376,8.919,3.875,19.051,2.641c7.479-0.904,9.103-5.157,20.584-0.334c-1.367,1.76-2.777,3.528-4.05,5.129
			c-0.173,0.215-0.328,0.412-0.492,0.62c-0.806-0.226-1.72-0.419-2.729-0.56c-3.055-0.424-7.056,1.631-12.713,2.509
			c-5.659,0.874-9.271-0.708-13.808-1.689c1.395,1.841,6.792,4.646,13.474,3.991c4.454-0.438,8.089-1.746,12.986-0.72
			c0.235,0.198,0.307,0.248,0.327,0.257c-1.019,1.315-1.78,2.346-1.975,2.749l20.031,3.016l-0.019,0.201
			c-0.021,0.217,0.111,0.501,0.365,0.778c0.464,0.517,1.41,1.064,2.642,1.064c0.605,0,1.211-0.133,1.802-0.394l0.083-0.036
			l0.079,0.03c2.247,0.856,7.373,2.513,11.897,3.972c2.709,0.875,5.191,1.676,6.938,2.265l3.136,1.146l14.346-26.12
			c0.196-0.129,0.405-0.267,0.628-0.415c3.767-2.535,7.294-3.132,10.957-4.482c3.669-1.351,6.599-5.451,6.966-8.041
			C110.052,38.265,103.291,37.145,98.356,38.174z M70.273,61.374c-2.397-1.773-7.827-2.76-10.12-1.928
			c-2.289,0.835-3.75-2.394-6.461-2.491c-2.713-0.109-7.297,0-9.381-0.631c-1.784-0.531-4.926-1.505-5.668-1.657
			c-0.072,0.025-0.131,0.031-0.172-0.002c-0.029-0.028,0.044-0.021,0.172,0.002c1.791-0.593,13.909-18.336,14.112-19.139
			c0.208-0.833,4.481,0.416,6.047-0.518c1.631-0.973,5.414-1.448,7.299-0.936c1.802,0.492,3.123,1.458,4.583,2.91
			c1.563,0.104,2.084-1.767,6.987-0.725c3.974,0.844,11.439,4.419,14.954,4.631l-8.623,17.712c-1.788,3.141-3.11,5.539-3.201,5.949
			C80.486,65.956,72.673,63.139,70.273,61.374z"
        />
      </g>
    </svg>
  );
};
export default SteinadlerLogo;
