import React from "react";

const TermsOfService = () => {
  return (
    <>
      <div className="w-full min-h-screen flex justify-center items-center bg-gray-100 dark:bg-gray-800">
        <div className="max-w-4xl w-full px-4 py-24">
          <div className="w-full mx-auto text-black rounded-lg shadow-lg bg-white dark:bg-gray-900 dark:text-powder">
            <div className="w-5/6 md:w-4/6 mx-auto py-4">
              <h1 className="text-5xl font-bold text-center text-marian_blue dark:text-powder p-3">
                Terms of Service
              </h1>

              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  Welcome to Steinadler's Digital Library ("the Service")
                  provided by STEINADLER VERLAG | ΕΚΔΟΣΕΙΣ ΚΟΥΝΑΛΑΚΗ ("we,"
                  "our," or "us"). These Terms of Service ("Terms") govern your
                  use of the Service. By accessing or using the Service, you
                  agree to be bound by these Terms.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>1. Description of the Service:</strong> Steinadler's
                  Digital Library hosts various language learning games that
                  accompany our German learning books.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>2. User Registration:</strong> User registration is
                  straightforward and may be done through normal signup or
                  Google signup. Users must create their own accounts and are
                  prohibited from sharing their accounts with others.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>3. User Conduct:</strong> Users must abide by all
                  applicable laws and regulations when using the Service.
                  Prohibited conduct includes but is not limited to spamming,
                  harassment, unauthorized access, and infringement of
                  intellectual property rights.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>4. User-Generated Content:</strong> There is no
                  user-generated content on the platform.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>5. Intellectual Property:</strong> All digital content
                  on this platform is owned by STEINADLER VERLAG | ΕΚΔΟΣΕΙΣ
                  ΚΟΥΝΑΛΑΚΗ.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>6. Payment and Subscription:</strong> There are no
                  payment or subscription terms associated with this web
                  application. All payments and subscriptions are handled
                  through our ecommerce website at "steinadlerverlag.com."
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>7. Limitation of Liability:</strong> We do not
                  guarantee the accuracy, completeness, or reliability of the
                  Service. We shall not be liable for any damages arising from
                  the use or inability to use the Service.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>8. Dispute Resolution:</strong> Any disputes arising
                  from these Terms shall be resolved through arbitration in
                  accordance with the rules of the arbitration association in
                  your jurisdiction.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>9. Changes to Terms:</strong> We reserve the right to
                  update or modify these Terms at any time without prior notice.
                  Your continued use of the Service after any changes indicates
                  your acceptance of the revised Terms.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>10. Termination:</strong> We reserve the right to
                  terminate or suspend your account without notice if you are
                  found to have shared your account or activation codes with
                  others.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>11. Governing Law:</strong> These Terms shall be
                  governed by and construed in accordance with the laws of Your
                  Jurisdiction, without regard to its conflict of law
                  provisions.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5">
                <p>
                  <strong>12. Contact Information:</strong> For any questions or
                  concerns regarding these Terms or the Service, please contact
                  us at{" "}
                  <a
                    href="mailto:info@steinadlerverlag.com"
                    className="text-marian_blue dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
                  >
                    info@steinadlerverlag.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
