import React from "react";
import { Link } from "react-router-dom";

const ContactPage = () => {
  return (
    <>
      <div className="w-full min-h-screen flex justify-center items-center bg-gray-100 dark:bg-gray-800">
        <div className="max-w-4xl w-full px-4 py-24">
          <div className="w-full mx-auto text-black rounded-lg shadow-lg bg-white dark:bg-gray-900 dark:text-gray-200">
            <div className="w-5/6 md:w-4/6 mx-auto py-4">
              <h1 className="text-5xl font-bold text-center text-marian_blue dark:text-powder p-3">
                Contact Us
              </h1>
              <div className="text-lg text-gray-500 dark:text-powder mb-5 px-6">
                <p>
                  If you have any questions or inquiries, please feel free to
                  contact us at{" "}
                  <a
                    href="mailto:info@steinadlerverlag.com"
                    className="text-marian_blue hover:text-bright_pink dark:text-harvest_gold hover:underline"
                  >
                    info@steinadlerverlag.com
                  </a>
                  .
                </p>
              </div>
              {/* <div className="text-md group opacity-25 hover:opacity-60 transition-all duration-150 mt-auto self-center">
                <Link
                  to="/"
                  className="hover:text-pumpkin transition-all duration-150 cursor-pointer"
                >
                  Return to Homepage
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
