import React, { useEffect, useState } from "react";
import {
  arrayUnion,
  collection,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { MdClear } from "react-icons/md";
import "./mini-blob.css";
import { useNavigate } from "react-router-dom";

const Activation = () => {
  const { user } = UserAuth();
  const [activationCode, setActivationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleActivation = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    // Create activation codes lookup query
    const activationCodesRef = collection(db, "activationCodes");
    const q = query(
      activationCodesRef,
      where("activation_code", "==", activationCode)
    );

    try {
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setErrorMessage("Invalid acctivation code. Please try again.");
      } else if (querySnapshot.size === 1) {
        const document = querySnapshot.docs[0];
        // Check if the code has already been activated or if it has expired
        if (document.data().activated === true) {
          if (document.data().users_information === user?.email) {
            setErrorMessage("Activation code already used by you.");
          } else {
            setErrorMessage("Activation code already used.");
          }
          return;
        }

        // Check if the user already exists in the 'users' collection
        const userDocRef = doc(db, "users", user?.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        const newProduct = document.data().activation_code;

        if (userDocSnapshot.exists()) {
          // Check if the users already owns this product
          const activatedProducts = userDocSnapshot.data().userLibrary;

          if (activatedProducts.includes(newProduct)) {
            setErrorMessage("Product already activated.");
            return;
          }

          // Update user's info
          await setDoc(
            userDocRef,
            {
              userLibrary: arrayUnion(newProduct),
            },
            { merge: true }
          );
        } else {
          setDoc(userDocRef, {
            userLibrary: [newProduct],
          });
        }
        // Update code's info
        const codeRef = doc(db, "activationCodes", document.id);
        const activationDate = new Date();
        const expirationDate = new Date(activationDate);
        expirationDate.setMonth(expirationDate.getMonth() + 18);
        updateDoc(codeRef, {
          activated: true,
          users_information: user?.email,
          activation_date: activationDate,
          expiration_date: expirationDate,
        });

        setSuccessMessage("Activation Successful!");
        // TODO: Redirect to the user's digital library page or perform other actions
      } else {
        //console.error("More than one document found for the activation code.");
      }
    } catch (error) {
      //console.error("Error during activation:", error);
    }
  };

  const handleClear = () => {
    setActivationCode("");
    setErrorMessage("");
  };
  const handleActivationCodeChange = (e) => {
    const inputText = e.target.value;

    if (inputText.length <= 12) {
      setActivationCode(inputText);
      setErrorMessage("");
    } else {
      setErrorMessage("Activation code must be 12 characters.");
    }
  };

  useEffect(() => {
    if (user.emailVerified === false) {
      return navigate("/emailVerification");
    }
  }, []);

  return (
    <div className="w-full flex-1 h-5/6 flex items-center justify-center ">
      <div className="mini-blob h-[50%] z-0"></div>
      <div className=" p-8 rounded-md z-10">
        <h1 className="text-5xl font-bold text-center mb-6 text-marian_blue dark:text-powder">
          Activation
        </h1>
        <p className="mb-4 text-center text-dim_gray dark:text-powder">
          Enter your 12-digit activation code:
        </p>
        <div className="relative mb-4">
          <input
            type="text"
            autoCorrect="false"
            value={activationCode}
            onChange={handleActivationCodeChange}
            className="w-full p-3  bg-white dark:bg-dim_gray-700 border border-dim_gray-300 dark:border-dim_gray-600 rounded-md focus:outline-none focus:ring focus:border-blue-300 "
          />

          {activationCode && (
            <button
              className="absolute top-0 items-center flex right-4 h-full   text-lg transiton-color duration-150  text-dim_gray hover:text-red-500 cursor-pointer"
              onClick={handleClear}
            >
              <MdClear />
            </button>
          )}
        </div>
        {errorMessage && (
          <div className="dark:text-red-400  text-red-500 mb-4 text-md">
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div classNamne=" mb-4 text-md text-center">
            <div className="text-green-600">{successMessage}</div>
            <div className="text-green-600">
              You can access this product for 18 months.
            </div>
          </div>
        )}

        <button
          onClick={handleActivation}
          className="bg-marian_blue disabled:opacity-50  enabled:hover:active:bg-pumpkin enabled:hover:bg-bright_pink dark:bg-gunmetal enabled:dark:hover:bg-harvest_gold enabled:dark:active:bg-pumpkin transition-all duration-150 text-powder  px-4 py-2 mt-1 rounded w-full"
          disabled={activationCode.length !== 12}
        >
          Activate
        </button>
      </div>
    </div>
  );
};

export default Activation;
