import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate("/");
  };

  return (
    <>
      <div className="w-full min-h-screen flex justify-center items-center bg-gray-100 dark:bg-gray-800">
        <div className="max-w-4xl w-full px-4 py-24">
          <div className="w-full mx-auto text-black rounded-lg shadow-lg bg-white dark:bg-gray-900 dark:text-gray-200">
            <div className="w-5/6 md:w-4/6 mx-auto py-4">
              <h1 className="text-5xl font-bold text-center text-marian_blue dark:text-powder p-3">
                Privacy Policy
              </h1>
              <div className="text-lg text-gray-500 dark:text-powder mb-5 px-6">
                <p>
                  Welcome to the Privacy Policy of Steinadler Verlag. This
                  document outlines the types of information we collect, how we
                  use it, and how we protect your privacy.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5 px-6">
                <p>
                  <strong>1. Information We Collect:</strong> We collect limited
                  personal information, specifically email addresses and
                  activation codes used for product activation.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5 px-6">
                <p>
                  <strong>2. Data Security:</strong> Our web application is
                  hosted on Google Firebase, ensuring robust security measures
                  are in place to protect your data.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5 px-6">
                <p>
                  <strong>3. Data Sharing:</strong> We do not share any of the
                  information collected with third parties, maintaining strict
                  confidentiality.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5 px-6">
                <p>
                  <strong>4. Data Protection:</strong> Your data is safeguarded
                  by Google Firebase, which adheres to industry-standard
                  security practices.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5 px-6">
                <p>
                  <strong>5. Use of Cookies:</strong> We do not utilize cookies
                  on our platform.
                </p>
              </div>
              <div className="text-lg text-gray-500 dark:text-powder mb-5 px-6">
                <p>
                  <strong>6. Contact Us:</strong> For any inquiries or concerns
                  regarding our Privacy Policy or your data privacy, please
                  contact us at{" "}
                  <a
                    href="mailto:info@steinadlerverlag.com"
                    className="text-marian_blue dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
                  >
                    info@steinadlerverlag.com
                  </a>
                  .
                </p>
              </div>
              {/* <div className="text-md group opacity-25 hover:opacity-60 transition-all duration-150 mt-auto self-center">
                <Link
                  to="/"
                  className="hover:text-pumpkin transition-all duration-150 cursor-pointer"
                >
                  Return to Homepage
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
