import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import "./blob.css";
import LogInProviders from "../components/LogInProviders";
import PasswordInput from "../components/PasswordInput";
import RepeatPasswordInput from "../components/RepeatPasswordInput";
import isPassword from "../utils/isPassword";
import isEmail from "../utils/isEmail";
import EmailInput from "../components/EmailInput";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const { user, signUp, error, setError } = UserAuth();
  const navigate = useNavigate();
  useEffect(() => {
    setError("");
  }, []);
  useEffect(() => {
    if (user != null) {
      navigate("/library");
    }
  }, [user]);

  const isRepeatPasswordValid = () => {
    return repeatPassword.length > 0 && password === repeatPassword;
  };

  useEffect(() => {
    isRepeatPasswordValid(repeatPassword);
  }, [repeatPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isPassword(password) && isRepeatPasswordValid()) {
        await signUp(email, password);
      } else {
        // setError(
        //   "Password requirements not met or repeat password does not match."
        // );
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div className="w-full h-screen overflow-hidden  relative justify-center flex my-4">
        <div className="blob top-20 flex z-0"></div>
        <div className="w-full px-4 py-24 z-10">
          <div className="w-6/6 sm:w-3/6 xl:w-2/6 h-auto mx-auto rounded">
            <div className="w-5/6 md:w-4/6 mx-auto py-4 ">
              <h1 className="text-5xl p-3 font-bold text-center text-marian_blue dark:text-powder">
                Sign up
              </h1>
              <LogInProviders buttonText={"Sign up with "} />

              <form
                onSubmit={handleSubmit}
                action=""
                className="w-full flex flex-col py-4"
              >
                <EmailInput email={email} setEmail={setEmail} title={true} />
                <PasswordInput
                  title="New Password"
                  password={password}
                  setPassword={setPassword}
                  checklist={true}
                />
                <RepeatPasswordInput
                  password={repeatPassword}
                  setPassword={setRepeatPassword}
                  repeatPassword={password}
                />

                {error && (
                  <p className="text-red-600 text-lg mt-2 whitespace-nowrap">
                    {error}
                  </p>
                )}
                <button
                  disabled={
                    !isPassword(password) ||
                    !isRepeatPasswordValid() ||
                    !isEmail(email)
                  }
                  className={`disabled:bg-gray-300 disabled:opacity-60 disabled:cursor-not-allowed
                      bg-marian_blue group hover:active:opacity-75 dark:bg-gunmetal hover:bg-green-500 
                   transition-all duration-150 text-powder py-3 my-4 rounded font-bold`}
                >
                  <div className="group-hover:scale-110 transition-all duration-150">
                    Sign up
                  </div>
                </button>
                <p className="flex flex-col items-center">
                  <span>Already subscribed?</span>
                  <Link
                    to="/login"
                    className="px-1 hover:scale-110 transition-all duration-150 items-center inline-flex text-marian_blue dark:text-powder hover:text-bright_pink dark:hover:text-harvest_gold"
                  >
                    Log in
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
