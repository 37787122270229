import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import { AuthContextProvider } from "./context/AuthContext";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Activation from "./pages/Activation";
import Stage from "./pages/Stage";
import Library from "./pages/Library";
import ProtectedRoute from "./components/ProtectedRoute";
import EmailVerification from "./pages/EmailVerification";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import ContactPage from "./pages/ContactUs";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import AuthActions from "./components/Auth-Actions";
import AuthUserActions from "./pages/AuthUserActions";
import EmailChanged from "./pages/EmailChanged";

function App() {
  return (
    <div
      className="flex overflow-x-hidden flex-col min-h-screen bg-stackoverflow_gray dark:bg-gunmetal"
      id="root"
    >
      <AuthContextProvider>
        <div className="flex-1 flex flex-col justify-center items-center">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/user"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="*" element={<NotFound />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<TermsOfService />} />
            <Route path="contact" element={<ContactPage />} />
            <Route
              path="/emailVerification"
              element={
                <ProtectedRoute>
                  <EmailVerification />
                </ProtectedRoute>
              }
            />
            <Route
              path="/emailChanged"
              element={
                <ProtectedRoute>
                  <EmailChanged />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stage"
              element={
                <ProtectedRoute>
                  <Stage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/activation"
              element={
                <ProtectedRoute>
                  <Activation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stage"
              element={
                <ProtectedRoute>
                  <Stage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/library"
              element={
                <ProtectedRoute>
                  <Library />
                </ProtectedRoute>
              }
            />
            <Route
              path="auth/action"
              element={
                <AuthActions>
                  <AuthUserActions />
                </AuthActions>
              }
            />
          </Routes>
          <Footer />
        </div>
      </AuthContextProvider>
    </div>
  );
}

export default App;
