import { Navigate, useSearchParams } from "react-router-dom";

function AuthAction({ children }) {
  const [searchParams] = useSearchParams();

  let mode = searchParams.get("mode");
  let oobCode = searchParams.get("oobCode");

  if (mode === "resetPassword") {
    const resetPasswordPath = `/reset-password?oobCode=${oobCode}`;

    return <Navigate to={resetPasswordPath} replace />;
  } else if (mode === "verifyEmail") {
    const confirmEmailPath = `/emailVerification?oobCode=${oobCode}`;

    return <Navigate to={confirmEmailPath} replace />;
  } else if (mode === "verifyAndChangeEmail") {
    const emailChangedPath = `/emailChanged?oobCode=${oobCode}`;

    return <Navigate to={emailChangedPath} replace />;
  }

  return children;
}
export default AuthAction;
