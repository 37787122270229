import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const RepeatPasswordInput = ({
  title,
  password,
  setPassword,
  repeatPassword,
  onKeyDown,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <div className="inline-flex space-x-3 mt-1 items-center">
        <p className="text-2xl font-bold text-dim_gray dark:text-powder">
          Repeat Password
        </p>{" "}
        {password && (
          <button
            onClick={() => setShowPassword(!showPassword)}
            className="self-center hover:scale-110 flex hover:text-harvest_gold text-dim_gray dark:text-powder"
          >
            {showPassword ? (
              <FaEyeSlash className="h-4 w-4" />
            ) : (
              <FaEye className="h-4 w-4" />
            )}
          </button>
        )}
      </div>
      <div className="relative group">
        <input
          value={password}
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={onKeyDown}
          className={`p-3 my-2 w-full bg-powder rounded ${
            repeatPassword.length > 0 && password === repeatPassword
              ? "outline outline-green-500"
              : password.length > 0 && "outline outline-harvest_gold"
          }`}
          type={showPassword ? "text" : "password"}
          placeholder="Repeat password"
        />
      </div>
    </>
  );
};

export default RepeatPasswordInput;
