import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./double-blob.css";
const kaktus1Colors = [
  "bg-picton_blue",
  "bg-royal_purple",
  "bg-blue_munsell",
  "bg-red_cmyk",
  "bg-caribbean_current",
  "bg-bright_pink",
  "bg-harvest_gold",
  "bg-pumpkin",
];
const kaktus2Colors = [
  "bg-red_cmyk",
  "bg-royal_purple",
  "bg-blue_munsell",
  "bg-[#51B848]",
  "bg-caribbean_current",
  "bg-bright_pink",
  "bg-harvest_gold",
  "bg-pumpkin",
];

const Library = () => {
  const { user } = UserAuth();
  const [expirationDates, setExpirationDates] = useState([]);
  const [userHasActivatedProduct, setUserHasActivatedProduct] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(
    () => sessionStorage.getItem("darkMode") === "true"
  );
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const storedDarkMode = sessionStorage.getItem("darkMode");
    setIsDarkMode(storedDarkMode === "true");
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (user && user.accessToken) {
        try {
          const idToken = await user.getIdToken(true); // Refresh the token
          const response = await axios.post(
            "https://us-central1-steinadler-digital.cloudfunctions.net/verifyToken/",
            {
              idToken: idToken,
            }
          );
          const kaktus1 = {
            title: "Kaktus 1",
            expirationDate: null,
            chapters: [
              {
                chapterTitle: "",
                products: [
                  {
                    title: "Farben",
                    url: response.data.message.signedUrls.kaktus1[0],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201/Kaktus%201-1%20Farben/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Zahlen",
                    url: response.data.message.signedUrls.kaktus1[1],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201/Kaktus%201-2%20Zahlen/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Familie",
                    url: response.data.message.signedUrls.kaktus1[2],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201/Kaktus%201-3%20Familie/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Tiere",
                    url: response.data.message.signedUrls.kaktus1[3],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201/Kaktus%201-4%20Tiere/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Unterwegs",
                    url: response.data.message.signedUrls.kaktus1[4],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201/Kaktus%201-5%20Unterwegs/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Spiele",
                    url: response.data.message.signedUrls.kaktus1[5],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201/Kaktus%201-6%20Spiele/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Geburtstag",
                    url: response.data.message.signedUrls.kaktus1[6],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201/Kaktus%201-7%20Geburtstag/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Jahreszeiten",
                    url: response.data.message.signedUrls.kaktus1[7],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201/Kaktus%201-8%20Jahreszeiten/story_content/thumbnail.jpg",
                  },
                ],
              },
            ],
          };
          const kaktus1Interaktiv = {
            title: "Kaktus 1 Interaktiv",
            expirationDate: null,
            chapters: [
              {
                chapterTitle: "",
                products: [
                  {
                    title: "Farben",
                    url: response.data.message.signedUrls.kaktus1[8],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201%20Interaktiv/Kaktus%201%20Interaktiv%20L1/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Zahlen",
                    url: response.data.message.signedUrls.kaktus1[9],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201%20Interaktiv/Kaktus%201%20Interaktiv%20L2/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Familie",
                    url: response.data.message.signedUrls.kaktus1[10],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201%20Interaktiv/Kaktus%201%20Interaktiv%20L3/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Tiere",
                    url: response.data.message.signedUrls.kaktus1[11],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201%20Interaktiv/Kaktus%201%20Interaktiv%20L4/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Unterwegs",
                    url: response.data.message.signedUrls.kaktus1[12],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201%20Interaktiv/Kaktus%201%20Interaktiv%20L5/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Spiele",
                    url: response.data.message.signedUrls.kaktus1[13],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201%20Interaktiv/Kaktus%201%20Interaktiv%20L6/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Geburtstag",
                    url: response.data.message.signedUrls.kaktus1[14],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201%20Interaktiv/Kaktus%201%20Interaktiv%20L7/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Jahreszeiten",
                    url: response.data.message.signedUrls.kaktus1[15],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%201%20Interaktiv/Kaktus%201%20Interaktiv%20L8/story_content/thumbnail.jpg",
                  },
                ],
              },
            ],
          };
          const kaktus2 = {
            title: "Kaktus 2",
            expirationDate: null,
            chapters: [
              {
                chapterTitle: "",
                products: [
                  {
                    title: "Tiere",
                    url: response.data.message.signedUrls.kaktus2[0],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202/Kaktus%202%20Kiko%20Spielt%20L1/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Mein Zimmer",
                    url: response.data.message.signedUrls.kaktus2[1],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202/Kaktus%202%20Kiko%20Spielt%20L2/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Schulsachen",
                    url: response.data.message.signedUrls.kaktus2[2],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202/Kaktus%202%20Kiko%20Spielt%20L3/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Obst und Gemüse",
                    url: response.data.message.signedUrls.kaktus2[3],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202/Kaktus%202%20Kiko%20Spielt%20L4/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Frühstück",
                    url: response.data.message.signedUrls.kaktus2[4],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202/Kaktus%202%20Kiko%20Spielt%20L5/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Körperteile",
                    url: response.data.message.signedUrls.kaktus2[5],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202/Kaktus%202%20Kiko%20Spielt%20L6/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Kleidung",
                    url: response.data.message.signedUrls.kaktus2[6],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202/Kaktus%202%20Kiko%20Spielt%20L7/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Natur",
                    url: response.data.message.signedUrls.kaktus2[7],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202/Kaktus%202%20Kiko%20Spielt%20L8/story_content/thumbnail.jpg",
                  },
                ],
              },
            ],
          };
          const kaktus2Interaktiv = {
            title: "Kaktus 2 Interaktiv",
            expirationDate: null,
            chapters: [
              {
                chapterTitle: "",
                products: [
                  {
                    title: "Tiere",
                    url: response.data.message.signedUrls.kaktus2[8],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202%20Interaktiv/Kaktus%202%20Interaktiv%20L1/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Mein Zimmer",
                    url: response.data.message.signedUrls.kaktus2[9],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202%20Interaktiv/Kaktus%202%20Interaktiv%20L2/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Schulsachen",
                    url: response.data.message.signedUrls.kaktus2[10],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202%20Interaktiv/Kaktus%202%20Interaktiv%20L3/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Obst und Gemüse",
                    url: response.data.message.signedUrls.kaktus2[11],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202%20Interaktiv/Kaktus%202%20Interaktiv%20L4/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Frühstück",
                    url: response.data.message.signedUrls.kaktus2[12],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202%20Interaktiv/Kaktus%202%20Interaktiv%20L5/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Körperteile",
                    url: response.data.message.signedUrls.kaktus2[13],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202%20Interaktiv/Kaktus%202%20Interaktiv%20L6/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Kleidung",
                    url: response.data.message.signedUrls.kaktus2[14],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202%20Interaktiv/Kaktus%202%20Interaktiv%20L7/story_content/thumbnail.jpg",
                  },
                  {
                    title: "Natur",
                    url: response.data.message.signedUrls.kaktus2[15],
                    thumb:
                      "https://storage.googleapis.com/steinadler-digital.appspot.com/Kaktus%202%20Interaktiv/Kaktus%202%20Interaktiv%20L8/story_content/thumbnail.jpg",
                  },
                ],
              },
            ],
          };
          let timestamps = response.data.message.expirationDates;

          // Convert Firestore timestamps to JavaScript Date objects
          let dates = timestamps.map(
            (timestamp) =>
              new Date(
                timestamp._seconds * 1000 +
                  Math.floor(timestamp._nanoseconds / 1000000)
              )
          );

          // Convert each Date object to a date string
          let dateStrings = dates.map((date) => date.toDateString());

          // Update the state with the new date strings
          setExpirationDates(dateStrings);

          // Check which signed URLs are available
          const signedUrls = response.data.message.signedUrls;

          let products = [];

          if (signedUrls.kaktus1.length > 0) {
            const kaktus1Date = dateStrings.shift(); // Use and remove the first date from the array
            kaktus1.expirationDate = kaktus1Date;
            kaktus1Interaktiv.expirationDate = kaktus1Date;
            products.push(kaktus1, kaktus1Interaktiv);
          }

          if (signedUrls.kaktus2.length > 0) {
            const kaktus2Date = dateStrings.shift(); // Use and remove the next date from the array
            kaktus2.expirationDate = kaktus2Date;
            kaktus2Interaktiv.expirationDate = kaktus2Date;
            products.push(kaktus2, kaktus2Interaktiv);
          }

          // Update state with the final list of products
          setAllProducts(products);
          setUserHasActivatedProduct(products.length > 0);
        } catch (error) {
          console.error("Error verifying token:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [user]);

  useEffect(() => {
    if (user.emailVerified === false) {
      navigate("/emailVerification");
    }
  }, [user, navigate]);

  const settings = {
    dots: true,
    draggable: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (isLoading) {
    return (
      <div className="flex h-96 items-center justify-center">
        <div className="z-10 tracking-wider animate-pulse text-xl dark:text-powder">
          Please wait...
        </div>
        <div className="blob spin scale-50 -z-0"></div>
      </div>
    );
  }

  return (
    <div
      className={`dark:text-powder h-5/6 flex-1 items-center justify-center flex w-screen ${
        isDarkMode ? "dark" : ""
      }`}
    >
      <div className="mx-32 flex-col flex justify-around space-y-32 w-full h-full">
        {!userHasActivatedProduct ? (
          <>
            <div className="text flex w-full flex-col space-y-4">
              <div className="text-5xl self-center text-gray-600">
                <p>Oops! Nothing Here Yet...</p>
              </div>
              <div className="text-lg self-center text-gray-500 mb-5">
                <p>
                  Uh-oh! It looks like your library is currently empty, and
                  there are no activated codes available at the moment.
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center w-full">
              {allProducts.length === 0 ? (
                <div className="h-full text-xl justify-center w-full flex-col items-center flex">
                  <div className="double-blob h-[60%] z-0"></div>
                  <div className="z-10 h-full text-xl justify-center w-full flex-col items-center flex">
                    <p>Do you have a code?</p>
                    <Link to="/activation">
                      <div className="hover:scale-110 transition-all duration-150">
                        <div className="text-pumpkin transition-all duration-150 dark:text-harvest_gold hover:opacity-70">
                          Click here to activate it.
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                allProducts.map((item, index) => <div key={index}>{item}</div>)
              )}
            </div>
            <div className="text-lg group opacity-60 hover:opacity-90 transition-all duration-150 mt-auto self-center flex flex-col items-center">
              Do you think this is an error?{" "}
              <a
                href="/contact"
                className="hover:text-pumpkin font-bold transition-all duration-150 cursor-pointer"
              >
                Contact us
              </a>
            </div>
          </>
        ) : (
          <div className="flex overflow-x-hidden flex-col flex-1">
            <div className="flex flex-col items-center justify-between w-full">
              {allProducts.map((product, index) => (
                <div key={index} className="my-20">
                  <div className="flex items-center flex-col sm:flex-row justify-between px-32">
                    <div className="title text-4xl font-semibold uppercase mb-3 text-marian_blue dark:text-powder">
                      {product.title}
                    </div>
                    <div className="bg-dim_gray text-white rounded-md space-x-5 flex text-sm">
                      <div className="bg-taupe rounded-s-md px-2 py-1">
                        {"available until"}
                      </div>
                      <div className="bg-dim_gray rounded-e-md px-2 py-1">
                        {product.expirationDate}
                      </div>
                    </div>
                  </div>
                  {product.chapters.map((chapter, i) => (
                    <div className="mt-5 w-screen px-10 sm:px-32" key={i}>
                      <div className="text-3xl">{chapter.chapterTitle}</div>
                      <Slider {...settings}>
                        {chapter.products.map((x, j) => {
                          // Determine color scheme based on product type
                          const colorScheme = product.title.includes("1")
                            ? kaktus1Colors
                            : kaktus2Colors;

                          return (
                            <button key={j} className="p-2">
                              <div
                                className={`${
                                  colorScheme[j % colorScheme.length]
                                } text-white rounded-xl hover:shadow hover:scale-105 transition-all`}
                                onClick={() => {
                                  navigate("/stage", {
                                    state: {
                                      url: x.url,
                                      colorIndex: j,
                                      allProducts: allProducts,
                                    },
                                  });
                                }}
                              >
                                <img
                                  alt="thumbnail"
                                  src={x.thumb}
                                  className="object-cover w-full rounded-t-xl"
                                />
                                {x.title}
                              </div>
                            </button>
                          );
                        })}
                      </Slider>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Library;
