import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import "./blob.css"; // Add this line for the blob styling
import { useSearchParams } from "react-router-dom";

const EmailChanged = () => {
  const { user, confirmUserEmail, logOut } = UserAuth();
  const [searchParams] = useSearchParams();
  const [confirmEmailSuccess, setConfirmEmailSuccess] = useState(
    user ? user.emailVerified : false
  );

  let oobCode = searchParams.get("oobCode");
  useEffect(() => {
    if (oobCode) {
      try {
        confirmUserEmail(oobCode);
        setConfirmEmailSuccess(true);

        // Logout after 5 seconds
        const logoutTimeout = setTimeout(() => {
          logOut();
        }, 3000);

        // Clear the timeout if the component unmounts before 5 seconds
        return () => clearTimeout(logoutTimeout);
      } catch (error) {
        alert(error);
      }
    }
  }, [oobCode]);

  return (
    <div className="flex flex-col h-screen items-center justify-center">
      {/* Blob for decoration */}
      <div className="blob  top-30 flex z-0"></div>

      {/* Render different content based on user verification status */}
      {confirmEmailSuccess === false ? (
        <div className="z-10">
          <h1 className="text-5xl font-bold text-center mb-6 text-marian_blue dark:text-powder">
            Email Verification Required
          </h1>
          <p className="mb-4 font-bold text-center text-dim_gray dark:text-powder">
            Please check your emails and verify your account to gain access.
          </p>
        </div>
      ) : (
        <div className="z-10">
          <h1 className="text-5xl font-bold text-center mb-6 text-marian_blue dark:text-powder">
            Email Changed
          </h1>
          <p className="mb-4 font-bold text-center w-96 text-dim_gray dark:text-powder">
            Congratulations! Your email has been successfully changed. Please
            login again.
          </p>
        </div>
      )}
    </div>
  );
};

export default EmailChanged;
