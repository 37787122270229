import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const StageSideDrawer = ({
  isOpen,
  onClose,
  url,
  setUrl,
  colorIndex,
  setColorIndex,
  allProducts,
}) => {
  const location = useLocation();
  const [colorScheme, setColorScheme] = useState([]);

  // Find the current product set based on the URL or other logic
  const currentProduct = location.state.allProducts.find((product) =>
    product.chapters
      .flatMap((chapter) => chapter.products)
      .some((p) => p.url === url)
  );

  const products = currentProduct ? currentProduct.chapters[0].products : [];

  useEffect(() => {
    if (currentProduct?.title.includes("1")) {
      setColorScheme([
        "bg-picton_blue",
        "bg-royal_purple",
        "bg-blue_munsell",
        "bg-red_cmyk",
        "bg-caribbean_current",
        "bg-bright_pink",
        "bg-harvest_gold",
        "bg-pumpkin",
      ]);
    } else {
      setColorScheme([
        "bg-red_cmyk",
        "bg-royal_purple",
        "bg-blue_munsell",
        "bg-[#51B848]",
        "bg-caribbean_current",
        "bg-bright_pink",
        "bg-harvest_gold",
        "bg-pumpkin",
      ]);
    }
  }, [currentProduct]);

  return (
    <div
      className={`${
        colorScheme[colorIndex] || "bg-gray-500"
      } absolute top-0 left-0 h-full w-64 text-white shadow-lg transition-all duration-300 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <ul className="py-4 w-full flex flex-col items-center text-center space-y-2">
        <div className="w-full font-bold text-xl m-4">
          {currentProduct ? currentProduct.title : "Product"}
        </div>
        {products.map((item, index) => (
          <li
            className={`${
              colorScheme[index % colorScheme.length] || "bg-gray-500"
            } cursor-pointer border border-white hover:bg-opacity-75 rounded-xl w-32 px-4 py-2 hover:scale-110 transition-all`}
            onClick={() => {
              setUrl(item.url);
              setColorIndex(index);
            }}
            key={index}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StageSideDrawer;
