import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const goToPrivacyPolicy = () => {
    navigate("/privacypolicy");
  };

  const goToTerms = () => {
    navigate("/terms");
  };

  const goToContactUs = () => {
    navigate("/contact");
  };

  return (
    <div className="bg-powder dark:bg-gray-600 text-white p-4 w-full mt-auto">
      <div className="flex flex-row items-center justify-between">
        <p className="text-xl font-bold text-marian_blue dark:text-powder">
          STEINADLER
        </p>

        <div className="ab">
          {/* <button
            onClick={goToPrivacyPolicy}
            className="text-dim_gray dark:text-powder pr-4 bg-transparent border-none"
          >
            Privacy Policy
          </button>
          <button
            onClick={goToTerms}
            className="text-dim_gray dark:text-powder pr-4 bg-transparent border-none"
          >
            Terms of Service
          </button> */}
          <button
            onClick={goToContactUs}
            className="text-dim_gray dark:text-powder bg-transparent border-none"
          >
            Contact Us
          </button>
        </div>
      </div>
      <div className="flex flex-row w-full">
        {" "}
        <p className=" text-gray-400">
          © {new Date().getFullYear()} STEINADLER. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
