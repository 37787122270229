import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  // signInWithRedirect,
  // getRedirectResult,
  confirmPasswordReset,
  sendEmailVerification,
  sendPasswordResetEmail,
  EmailAuthProvider,
  // connectAuthEmulator,
  signInWithPopup,
  deleteUser,
  applyActionCode,
  updatePassword,
  verifyBeforeUpdateEmail,
  reauthenticateWithCredential,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const AuthContext = createContext();
// if (process.env.NODE_ENV === "development") {
//   connectAuthEmulator(auth, "http://localhost:9099");
// }
export function AuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);

  const addUserToDB = async (newUser) => {
    try {
      const userDocRef = doc(db, "users", newUser?.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        setDoc(userDocRef, {
          userLibrary: [],
          email: newUser.email,
          displayName: newUser.displayName,
        });
      }
    } catch (error) {
      //console.error("In addUserToDB", error);
    }
  };

  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      if (result && result.user) {
        addUserToDB(result.user);
      }
    } catch (error) {
      //console.error("Error during Google sign-in:", error);
    }
  };

  function signUp(email, password, displayName) {
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        addUserToDB(res.user);
        // Send email verification
        sendEmailVerification(res.user)
          .then(() => {
            console.log("Verification email sent successfully");
          })
          .catch((error) => {
            //console.error("Error sending verification email:", error);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/email-already-in-use") {
          setError("Email already in use.");
        } else {
          //console.error(`Error during signup: ${errorMessage}`);
          // throw error;
        }
      });
  }

  function logOut() {
    return signOut(auth);
  }
  async function logIn(email, password) {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (errorCode === "auth/invalid-credential") {
        setError("Invalid email or password. Please try again.");
      } else if (errorCode === "auth/too-many-requests") {
        setError(
          "Access to this account has been temporarily disabled due to many failed login attempts. Please try again later. "
        );
      } else {
        //console.error(`Error during login: ${errorMessage}`);
      }
      throw error; // Re-throw the error to propagate it up the call stack
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    // handleRedirectResult();

    return () => {
      unsubscribe();
    };
  }, []);
  const resetPasswordEmail = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      // Handle errors such as invalid email, user not found, etc.
      setError("Error sending password reset email. Please try again.");
      //console.error("Error sending password reset email:", error.message);
      throw error;
    }
  };
  const updateUserEmail = async (email, password) => {
    try {
      await reauthenticate(user, user.email, password);
      await verifyBeforeUpdateEmail(user, email);

      // await updateEmail(user, email);

      await updateDoc(doc(db, "users", user?.uid), {
        email: email,
      });
      // await signOut(auth);
    } catch (error) {
      setError("Error updating email. Please re-login and try again.");
      //console.error(error.message);
      throw error;
    }
  };
  const updateUserPassword = async (newPassword, curremtPassword) => {
    try {
      await reauthenticate(user, user.email, curremtPassword);
      await updatePassword(user, newPassword);
    } catch (error) {
      setError("Error updating password. Please re-login and try again.");
      //console.error(error.message);
      throw error;
    }
  };

  const confirmThePasswordReset = async (oobCode, newPassword) => {
    if (!oobCode && !newPassword) return;

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
    } catch (error) {
      setError("An unexpected error occured. Please try again.");
      //console.error("An unexpected error occured:", error.message);
      throw error;
    }
  };
  const confirmUserEmail = async (oobCode) => {
    if (!oobCode) return;

    try {
      await applyActionCode(auth, oobCode);
    } catch (error) {
      //console.error(error);
      throw error;
    }

    return;
  };
  const reauthenticate = async (user, email, password) => {
    const credential = EmailAuthProvider.credential(email, password);
    await reauthenticateWithCredential(user, credential);
  };
  const deleteUserAccount = async (user, password) => {
    try {
      await reauthenticate(user, user.email, password);
      await deleteUser(user);
    } catch (error) {
      //console.error("Error deleting user:", error.message);
      throw error;
    }
  };
  return (
    <AuthContext.Provider
      value={{
        googleSignIn,
        signUp,
        logIn,
        resetPasswordEmail,
        confirmThePasswordReset,
        logOut,
        reauthenticate,
        user,
        error,
        deleteUserAccount,
        setError,
        updateUserEmail,
        updateUserPassword,
        confirmUserEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function UserAuth() {
  return useContext(AuthContext);
}
