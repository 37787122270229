import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StageSideDrawer from "./StageSideDrawer";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

const Stage = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [colorIndex, setColorIndex] = useState(
    location?.state?.colorIndex || 0
  );
  const [url, setUrl] = useState(location?.state?.url);
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const currentProduct = location.state.allProducts.find((product) =>
    product.chapters
      .flatMap((chapter) => chapter.products)
      .some((p) => p.url === url)
  );
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Adding manual 200ms delay so that it correctly reads emailVerification attribute
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading && user.emailVerified === false) {
      navigate("/emailVerification");
    }
  }, [user, loading, navigate]);

  // Return this message if the url parameter is empty
  if (!location.state?.url) {
    return (
      <div className="h-screen flex items-center">
        <div className="dark:text-powder h-full w-full flex">
          <div className="mx-32 flex-col flex justify-around space-y-10 w-full h-full">
            <div className="flex flex-col justify-center w-full">
              <div className="h-full text-xl justify-center w-full flex-col items-center flex">
                <div className="blob h-[60%] z-0"></div>
                <div className="h-full text-xl justify-center w-full flex-col items-center flex z-0">
                  <p className="text-3xl text-center text-gray-600 dark:text-powder">
                    Oops! No content here yet...
                  </p>
                  <p className="text-lg text-center text-gray-500 dark:text-powder">
                    Looks like there's nothing to display at the moment.
                  </p>
                  <div
                    onClick={() => navigate("/library")}
                    className="hover:scale-110 transition-all duration-150"
                  >
                    <div className="text-pumpkin transition-all duration-150 dark:text-harvest_gold hover:opacity-70 cursor-pointer text-center mt-4">
                      Pick something from your library
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Determine the color scheme based on the product
  const colorScheme = currentProduct?.title.includes("1")
    ? [
        "bg-picton_blue",
        "bg-royal_purple",
        "bg-blue_munsell",
        "bg-red_cmyk",
        "bg-caribbean_current",
        "bg-bright_pink",
        "bg-harvest_gold",
        "bg-pumpkin",
      ]
    : [
        "bg-red_cmyk",
        "bg-royal_purple",
        "bg-blue_munsell",
        "bg-[#51B848]",
        "bg-caribbean_current",
        "bg-bright_pink",
        "bg-harvest_gold",
        "bg-pumpkin",
      ];

  return (
    <>
      <div className="dark:text-powder flex justify-center w-full p-3 h-screen relative">
        <>
          <button
            className={`top-[50%] ${
              isDrawerOpen ? "hover:pl-2 left-64" : "hover:pl-8 left-0"
            } px-4 py-2 ${
              colorScheme[colorIndex] || "bg-gray-500"
            } hover:scale-110 transition-all text-white rounded-r-full absolute`}
            onClick={toggleDrawer}
          >
            {isDrawerOpen ? (
              <FaCaretLeft size={32} />
            ) : (
              <FaCaretRight size={32} />
            )}
          </button>
          <StageSideDrawer
            isOpen={isDrawerOpen}
            onClose={toggleDrawer}
            setUrl={setUrl}
            colorIndex={colorIndex}
            setColorIndex={setColorIndex}
            url={url}
            allProducts={location.state.allProducts}
          />
        </>
        <iframe
          title="Interactive game"
          allowFullScreen
          className="w-5/6 h-5/6 rounded-3xl"
          src={url}
        />
      </div>
    </>
  );
};

export default Stage;
