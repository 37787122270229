import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col h-screen items-center justify-center">
      {/* Blob for decoration */}
      <div className="blob top-30 flex z-0"></div>
      <div className="flex flex-col h-screen items-center justify-center z-10">
        <h1 className="text-5xl font-bold text-center mb-6 text-marian_blue dark:text-powder">
          404 - Not Found
        </h1>
        <p className="mb-4 font-bold text-center text-dim_gray dark:text-powder">
          Oops! The page you are looking for doesn't exist.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
