import React, { useState, useEffect } from "react";
import { FaMoon, FaSun, FaUserCog } from "react-icons/fa";
import { LuTextCursorInput } from "react-icons/lu";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import SteinadlerLogo from "../SteinadlerLogo";

import {
  IoLibraryOutline,
  IoLogInOutline,
  IoLogOutOutline,
} from "react-icons/io5";
import { Disclosure } from "@headlessui/react";

const Navbar = () => {
  const [isDarkMode, setDarkMode] = useState(() => {
    // Retrieve dark mode preference from session storage, default to false if not found
    const storedDarkMode = sessionStorage.getItem("darkMode");
    return storedDarkMode ? JSON.parse(storedDarkMode) : false;
  });
  const { user, logOut } = UserAuth();
  const navigate = useNavigate();

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);

    // Toggle color scheme
    const body = document.body;
    if (isDarkMode) {
      body.classList.remove("dark");
    } else {
      body.classList.add("dark");
    }
  };

  useEffect(() => {
    // Update the class on the body when dark mode changes
    const body = document.body;
    if (isDarkMode) {
      body.classList.add("dark");
    } else {
      body.classList.remove("dark");
    }

    // Store dark mode preference in session storage
    sessionStorage.setItem("darkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Disclosure
      as="nav"
      className="bg-stackoverflow_gray w-full shadow-sm dark:bg-gunmetal "
    >
      {({ open }) => (
        <>
          <div className="flex flex-row items-center w-full justify-between space-x-12 p-4">
            <div className="flex items-center">
              <Link to="/">
                <div className="text-marian_blue dark:text-powder inline-flex text-5xl font-bold cursor-pointer  flex-items-center h-full">
                  <SteinadlerLogo />
                  <div className="hidden sm:block">STEINADLER</div>
                </div>
              </Link>
            </div>

            {/* This part is shown on larger screens */}
            <div className="hidden  w-full  items-center justify-end lg:flex space-x-4">
              {user?.email ? (
                <div className="flex text-xl space-x-11 h-full flex-1 ">
                  <Link to="/library">
                    <button className="text-marian_blue  hover:scale-110 items-center flex font-bold hover:opacity-70 transition-all duration-150 dark:text-powder dark:border-powder">
                      <IoLibraryOutline className="m-1" /> My Library
                    </button>
                  </Link>
                  <Link to="/activation">
                    <button className="text-marian_blue  hover:scale-110 items-center flex font-bold hover:opacity-70 transition-all duration-150 dark:text-powder dark:border-powder">
                      <LuTextCursorInput className="m-1" /> Activation
                    </button>
                  </Link>
                </div>
              ) : null}

              <div className="flex items-center text-gunmetal dark:text-powder">
                {/* {user?.displayName ? <p> Hi, {user?.displayName}!</p> : null} */}
                <button
                  onClick={toggleDarkMode}
                  className="px-2 py-2 mx-4 hover:opacity-70 hover:scale-110 transition-all duration-150"
                >
                  {isDarkMode ? (
                    <FaSun size={24} className="text-powder" />
                  ) : (
                    <FaMoon size={24} className="text-marian_blue" />
                  )}{" "}
                </button>
                {user && (
                  <Link to="/user">
                    <button className="px-2 py-2 mx-4 hover:opacity-70 hover:scale-110 transition-all duration-150">
                      <FaUserCog
                        size={24}
                        className="dark:text-powder text-marian_blue"
                      />{" "}
                    </button>
                  </Link>
                )}
                {user?.email ? (
                  <button
                    onClick={handleLogout}
                    className=" group text-marian_blue items-center inline-flex border-marian_blue border-2 rounded-full px-6 py-2 dark:bg-gunmetal dark:text-powder dark:border-powder hover:opacity-70"
                  >
                    <div className="group-hover:scale-110 transition-all duration-150 items-center inline-flex">
                      <IoLogOutOutline className="mr-1" />
                      Log out
                    </div>
                  </button>
                ) : (
                  <Link to="/login">
                    <button className="group text-marian_blue  border-marian_blue border-2 rounded-full px-6 py-2 dark:bg-gunmetal dark:text-powder dark:border-powder hover:opacity-70 ">
                      <div className="group-hover:scale-110 transition-all duration-150 items-center inline-flex">
                        <IoLogInOutline className="mr-1 " /> Log in
                      </div>
                    </button>
                  </Link>
                )}
              </div>
            </div>

            {/* This part is shown on smaller screens */}
            <div className="lg:hidden w-10 h-10 dark:text-powder">
              <Disclosure.Button aria-label="Menu">
                {open ? (
                  <RxCross2
                    color={isDarkMode ? "#F0F4EF" : "#20409A"}
                    className="w-10 h-10 hover:scale-110"
                  />
                ) : (
                  <RxHamburgerMenu
                    color={isDarkMode ? "#F0F4EF" : "#20409A"}
                    className="w-10 hover:scale-110 h-10"
                  />
                )}
              </Disclosure.Button>
            </div>
          </div>

          {/* Mobile Menu */}
          <Disclosure.Panel className="lg:hidden">
            <div className="flex flex-col text-xl space-y-4 dark:bg-gunmetal p-4">
              {user?.email ? (
                <>
                  <Link to="/library">
                    <button className="text-marian_blue  hover:scale-110 items-center flex font-bold hover:opacity-70 transition-all duration-150 dark:text-powder dark:border-powder">
                      <IoLibraryOutline className="m-1" /> My Library
                    </button>
                  </Link>
                  <Link to="/activation">
                    <button className="text-marian_blue  hover:scale-110 items-center flex font-bold hover:opacity-70 transition-all duration-150 dark:text-powder dark:border-powder">
                      <LuTextCursorInput className="m-1" /> Activation
                    </button>
                  </Link>
                </>
              ) : null}

              <div className="flex items-center text-powder">
                {/* {user?.displayName ? <p>Hi, {user?.displayName}!</p> : null} */}
                <button
                  aria-label="Light/Dark Mode"
                  onClick={toggleDarkMode}
                  className="px-2 py-2 mx-4 hover:opacity-70 hover:scale-110 transition-all duration-150"
                >
                  {isDarkMode ? (
                    <FaSun size={24} className="text-powder" />
                  ) : (
                    <FaMoon size={24} className="text-marian_blue" />
                  )}{" "}
                </button>
                {user && (
                  <Link to="/user">
                    <button className="px-2 py-2 mx-4 hover:opacity-70 hover:scale-110 transition-all duration-150">
                      <FaUserCog
                        size={24}
                        className="dark:text-powder text-marian_blue"
                      />{" "}
                    </button>
                  </Link>
                )}

                {user?.email ? (
                  <button
                    onClick={handleLogout}
                    className=" group text-marian_blue  border-marian_blue  dark:text-powder dark:border-powder items-center inline-flex border-2 rounded-full px-6 py-2 hover:opacity-70"
                  >
                    <div className="group-hover:scale-110 transition-all duration-150 items-center inline-flex">
                      <IoLogOutOutline className="mr-1" />
                      Log out
                    </div>
                  </button>
                ) : (
                  <Link to="/login">
                    <button className="group text-marian_blue  border-marian_blue  dark:text-powder dark:border-powder border-2 rounded-full px-6 py-2 hover:opacity-70">
                      <div className="group-hover:scale-110 transition-all duration-150 items-center inline-flex">
                        <IoLogInOutline className="mr-1" /> Log in
                      </div>
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
