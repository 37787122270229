// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const productStorage = getStorage(app, "gs://steinadler-test-bucket");

export const productStorageRef = ref(
  productStorage,
  "kaktus_1/Kaktus 1-1 Farben/story.html"
);

// Function to get download URL
// export const getUrl = async () => {
//   try {
//     const user = auth.currentUser;
//     if (user) {
//       const url = await getDownloadURL(productStorageRef);
//       return url;
//     } else {
//       console.error("User is not authenticated");
//     }
//   } catch (error) {
//     console.error("Error getting download URL", error);
//   }
// };

// Check if the user is authenticated
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     // User is signed in
//     getUrl().then((url) => {
//       console.log("Download URL:", url);
//     });
//   } else {
//     // No user is signed in, sign in anonymously for demo purposes
//     signInAnonymously(auth)
//       .then(() => {
//         console.log("Signed in anonymously");
//       })
//       .catch((error) => {
//         console.error("Error signing in anonymously", error);
//       });
//   }
// });
