import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { FaExclamationTriangle, FaPencilAlt, FaUserCog } from "react-icons/fa";
import isEmail from "../utils/isEmail";
import isPassword from "../utils/isPassword";
import PasswordInput from "../components/PasswordInput";
import RepeatPasswordInput from "../components/RepeatPasswordInput";
import EmailInput from "../components/EmailInput";
import DeleteAccountModal from "../components/DeleteAccountModal";

const Profile = () => {
  const { user, updateUserEmail, updateUserPassword, error, setError } =
    UserAuth();
  const [newEmail, setNewEmail] = useState("");
  const [emailEditing, setEmailEditing] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordForEmailChange, setPasswordForEmailChange] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [pwChangeSuccess, setPwChangeSuccess] = useState("");
  const [mailChangeSuccess, setMailChangeSuccess] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setError("");
  }, []);

  const isRepeatPasswordValid = () => {
    return repeatPassword.length > 0 && newPassword === repeatPassword;
  };

  useEffect(() => {
    isRepeatPasswordValid(repeatPassword);
  }, [repeatPassword]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== repeatPassword) {
      setError("Passwords did not match.");
      return;
    }

    try {
      if (user.email && oldPassword && newPassword && repeatPassword) {
        await updateUserPassword(repeatPassword, oldPassword);
        setPwChangeSuccess("Password reset successful!");
        setNewPassword("");
        setRepeatPassword("");
        setOldPassword("");
        setError("");
      }
    } catch (error) {
      if (error.code === "auth/invalid-action-code") {
        setError("Something is wrong; try again later.");
        setPwChangeSuccess("");
      } else if (error.code === "auth/wrong-password") {
        setError("The current password is incorrect.");
        setPwChangeSuccess("");
      } else if (error.code === "auth/invalid-credential") {
        setError("The current password is incorrect.");
        setPwChangeSuccess("");
      } else {
        setError("Something went wrong; try again later.");
        setPwChangeSuccess("");
        // console.log(error.message);
      }
    }
  };
  const handleEmailChange = async (e) => {
    e.preventDefault();
    try {
      await updateUserEmail(newEmail, passwordForEmailChange);
      setNewEmail("");
      setMailChangeSuccess("Email change successful!");
    } catch (error) {
      //console.log(error.message);
    }
  };

  return (
    <div className="w-full flex items-center text-dim_gray justify-center ">
      <div className=" flex items-center justify-center p-5 w-full  ">
        <div className=" lg:w-2/3 overflow-hidden rounded-xl bg-gray-50 dark:bg-slate-700 px-8 shadow">
          <div className="pt-4">
            <h1 className="py-2 text-dim_gray dark:text-powder text-2xl inline-flex items-center font-semibold">
              <FaUserCog className="me-2" />
              Account settings
            </h1>
            <p className=" text-dim_gray dark:text-powder">
              Here you can update your email and password.
            </p>
          </div>
          <hr className="mt-4 mb-8" />
          <p className="py-2 text-2xl font-semibold text-dim_gray dark:text-powder">
            Email Address
          </p>
          <div className="flex flex-col sm:flex-row place-items-start w-full ">
            {emailEditing ? (
              <div className="flex flex-col w-full">
                <form
                  onSubmit={handleEmailChange}
                  className="w-full flex flex-col py-1"
                >
                  <EmailInput
                    email={newEmail}
                    setEmail={setNewEmail}
                    title={false}
                  />
                  <PasswordInput
                    password={passwordForEmailChange}
                    setPassword={setPasswordForEmailChange}
                    title="Current password"
                    checklist={false}
                  />
                  <button
                    disabled={
                      !isEmail(newEmail) || !isPassword(passwordForEmailChange)
                    }
                    className={`disabled:bg-gray-300 disabled:opacity-60 disabled:cursor-not-allowed
                bg-marian_blue group hover:active:opacity-75 dark:bg-gunmetal hover:bg-green-500 
                transition-all duration-150 text-powder py-3 my-4 rounded font-bold`}
                  >
                    <div className="group-hover:scale-110 transition-all duration-150">
                      Send email confirmation
                    </div>
                  </button>

                  {error && isEmail(newEmail) && (
                    <p className="text-red-600 text-lg mt-2 whitespace-nowrap">
                      {error}
                    </p>
                  )}
                  {pwChangeSuccess && (
                    <p className="text-center text-green-700 dark:text-green-500 font-extrabold text-lg mt-2">
                      {mailChangeSuccess}
                    </p>
                  )}
                </form>
              </div>
            ) : (
              <>
                {" "}
                <p className=" text-dim_gray dark:text-powder whitespace-nowrap">
                  Your email address is
                </p>
                <strong className="ms-1 text-dim_gray dark:text-powder">
                  {user?.email}
                </strong>
              </>
            )}
            <div className="flex my-1 justify-end w-full ">
              <button
                onClick={() => setEmailEditing(!emailEditing)}
                className={`${
                  emailEditing ? "ms-4" : "ms-auto"
                } inline-flex text-sm font-semibold text-marian_blue dark:text-harvest_gold hover:scale-105 hover:text-bright_pink underline decoration-2`}
              >
                {emailEditing ? (
                  "Cancel"
                ) : (
                  <FaPencilAlt className="me-2 self-start text-lg" />
                )}
              </button>
            </div>
          </div>
          <hr className="mt-4 mb-8" />

          <div className="flex items-center  w-full md:w-1/2 ">
            <form
              onSubmit={handlePasswordChange}
              action=""
              className="w-full flex flex-col py-1"
            >
              <PasswordInput
                setPassword={setOldPassword}
                password={oldPassword}
                title="Current Password"
                checklist={false}
              />
              <PasswordInput
                setPassword={setNewPassword}
                password={newPassword}
                title="New Password"
                checklist={true}
              />
              <RepeatPasswordInput
                setPassword={setRepeatPassword}
                password={repeatPassword}
                repeatPassword={newPassword}
              />
              <button
                disabled={
                  !isPassword(newPassword) ||
                  !isRepeatPasswordValid() ||
                  !isPassword(oldPassword)
                }
                className={`disabled:bg-gray-300 disabled:opacity-60 disabled:cursor-not-allowed
                bg-marian_blue group hover:active:opacity-75 dark:bg-gunmetal hover:bg-green-500 
                transition-all duration-150 text-powder py-3 my-4 rounded font-bold`}
              >
                <div className="group-hover:scale-110 transition-all duration-150">
                  Save Password
                </div>
              </button>
            </form>
          </div>
          <div className="flex-col sm:flex-row flex mt-2">
            <p className="me-auto dark:text-powder text-dim_gray">
              Can't remember your current password?{" "}
            </p>
            <Link to="/forgot-password">
              <div className="text-sm ms-auto font-semibold text-marian_blue dark:text-harvest_gold hover:scale-105 hover:text-bright_pink underline decoration-2">
                Recover Account
              </div>
            </Link>
          </div>
          <div className="p-3">
            {error && isPassword(newPassword) && (
              <p className="text-red-600 text-lg mt-2 whitespace-nowrap">
                {error}
              </p>
            )}
            {pwChangeSuccess && (
              <p className="text-center text-green-700 dark:text-green-500 font-extrabold text-lg mt-2">
                {pwChangeSuccess}
              </p>
            )}
          </div>
          <hr className="mt-4 mb-8" />

          <div className="mb-10">
            <p className="py-2 text-xl font-semibold  dark:text-powder text-dim_gray">
              Delete Account
            </p>
            <p className="inline-flex items-center rounded-full bg-rose-100 px-4 py-1 text-rose-600">
              <FaExclamationTriangle className="me-2 " />
              Proceed with caution
            </p>
            <p className="mt-2  dark:text-powder text-dim_gray">
              Make sure you have taken backup of your account in case you ever
              need to get access to your data. We will completely wipe your
              data. There is no way to access your account after this action.
            </p>
            <button
              onClick={() => setOpenModal(true)}
              className="ml-auto text-sm font-semibold text-rose-600 underline decoration-2"
            >
              Continue with deletion
            </button>
            {openModal && (
              <DeleteAccountModal
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
