import React from "react";
import isEmail from "../utils/isEmail";

const EmailInput = ({ email, setEmail, title }) => {
  return (
    <>
      {title && (
        <p className="text-2xl font-bold text-dim_gray dark:text-powder">
          Email
        </p>
      )}
      <input
        onChange={(e) => setEmail(e.target.value)}
        className={`p-3 my-2 bg-powder rounded ${
          isEmail(email)
            ? "outline outline-green-500"
            : email.length > 0 && "outline outline-harvest_gold"
        }`}
        type="email"
        placeholder="Email"
        autoComplete="email"
        value={email}
      />
    </>
  );
};

export default EmailInput;
