import React from "react";
import { FcGoogle } from "react-icons/fc";
import { UserAuth } from "../context/AuthContext";

const LogInProviders = ({ buttonText }) => {
  const { googleSignIn } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full flex flex-col py-">
      <button
        onClick={handleGoogleSignIn}
        className="bg-white border-dim_gray/30 border-2 py-3 my-2 rounded text-black"
      >
        <div className="flex items-center justify-center w-full text-1xl round-black">
          <FcGoogle className="mx-4 text-3xl" />
          <p>{buttonText}Google</p>
        </div>
      </button>
      {/* <button
        onClick={handleGoogleSignIn}
        className="bg-facebook_blue py-3 my-2 rounded text-white"
      >
        <div className="flex items-center justify-center w-full text-1xl round-black">
          <AiFillFacebook className="mx-4 text-3xl" />
          <p>{buttonText}Facebook</p>
        </div>
      </button> */}
    </div>
  );
};

export default LogInProviders;
