import isMixedCase from "./isMixedCase";

const isPassword = (password) => {
  // Password should be at least 8 characters long
  if (password.length < 8) {
    return false;
  }

  if (!isMixedCase(password)) {
    return false;
  }

  // Check for at least one number and one symbol
  const hasNumber = /\d/.test(password);
  // const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  if (!hasNumber) {
    return false;
  }

  // Password meets all requirements
  return true;
};

export default isPassword;
